@import '../../global.scss';

.skills {
  padding: 80px 0; // Removed right padding
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%; // Added to ensure containment
  box-sizing: border-box; // Added to include padding in width calculation

  .skills-box {
    width: 60%;
    height: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    padding: 10px;
    justify-items: center;
    box-sizing: border-box; // Added

    img {
      @media (max-width: 600px) {
        height: 40px;
      }
    }

    i {
      @media (max-width: 600px) {
        transform: scale(0.5);
        height: 100px;
      }
    }

    @include mobile {
      width: 100%;
      height: 80%;
      font-size: smaller;
      padding: 30px 10px; // Added horizontal padding
      gap: 20px; // Reduced gap for mobile
    }
  }
}
