@import '../../global.scss';

.footer {
  width: 100vw; // Changed to viewport width
  height: 50px;
  background-color: rgba($background-color-light, 0.8);
  backdrop-filter: blur(10px);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  overflow-x: hidden; // Prevent horizontal scroll

  .content {
    max-width: 1400px;
    height: 100%;
    margin: 0 auto;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box; // Ensure padding is included in width

    .credit {
      font-size: 0.9rem;
      letter-spacing: 0.5px;
      white-space: nowrap; // Prevent text wrapping

      .name {
        font-weight: 600;
        color: $mainColor;
      }
    }

    .social-links {
      display: flex;
      gap: 20px;
      align-items: center;

      a {
        color: inherit;
        opacity: 0.7;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: 1;
          color: $mainColor;
          transform: translateY(-2px);
        }

        svg {
          font-size: 20px;
        }
      }
    }
  }

  @include mobile {
    height: 45px;
    background-color: $background-color-light;
    width: 100%; // Reset width for mobile

    .content {
      padding: 0 15px; // Reduced padding for mobile
      gap: 10px; // Add gap between elements

      .credit {
        font-size: 0.75rem; // Slightly smaller font
      }

      .social-links {
        gap: 12px; // Reduced gap between icons

        svg {
          font-size: 16px; // Smaller icons
        }
      }
    }
  }
}

// Dark mode styles
body.dark-mode .footer {
  background-color: rgba($mainColor, 0.8);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  .content {
    .credit {
      .name {
        color: $secColor;
      }
    }

    .social-links a:hover {
      color: $secColor;
    }
  }

  @include mobile {
    background-color: $mainColor;
  }
}
