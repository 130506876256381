// app.scss
@import './global.scss';

.app {
  height: 100vh;
  overflow: hidden;
  width: 100%; // Added
  position: fixed; // Added to prevent body overflow
  top: 0;
  left: 0;

  .sections {
    width: 100%;
    height: calc(100vh - 70px);
    position: relative;
    top: 70px;
    overflow-y: auto;
    overflow-x: hidden; // Added to prevent horizontal scroll
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;

    @include mobile {
      top: 55px;
      height: calc(100vh - 55px);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      width: 100%;
      min-height: calc(100vh - 70px);
      scroll-snap-align: start;
      box-sizing: border-box; // Added
      overflow-x: hidden; // Added

      @include mobile {
        min-height: calc(100vh - 55px);
      }
    }

    @include mobile {
      scroll-behavior: smooth;
      scroll-snap-type: none;
    }
  }
}
