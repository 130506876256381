@import '../../global.scss';

.topbar {
  width: 100%;
  color: inherit;
  position: fixed;
  top: 0;
  z-index: 1000; // Ensure high z-index
  left: 0;
  background-color: $background-color-light; // Default background color
  transition: all 1s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Add shadow for better visibility

  @include mobile {
    height: 58px;
  }

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .logo {
        font-size: 30px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;

        @include mobile {
          font-size: 25px;
        }
      }

      .itemContainer {
        text-decoration: none;
        display: flex;
        align-items: center;
        margin: 0 25px;

        @include mobile {
          display: none;
        }

        .icon {
          font-size: 18px;
          margin-right: 5px;
        }

        span {
          font-size: 15px;
          font-weight: 500;
        }

        .email {
          display: flex;
          align-items: center;
          margin-left: 30px;
          color: inherit;
        }
      }
    }

    .right {
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          transform-origin: left;
          transition: all 2s ease;
        }
      }
    }
  }

  &.active {
    background-color: $mainColor;
    color: $secColor;

    .hamburger {
      span {
        &:first-child {
          background-color: $secColor;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          background-color: $secColor;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

// Dark mode styles
body.dark-mode .topbar {
  background-color: $mainColor; // Dark mode background color
  color: $background-color-light; // Dark mode text color

  .logo,
  .itemContainer span,
  .email,
  .hamburger span {
    color: $background-color-light; // Dark mode text color
  }
}
