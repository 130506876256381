@import '../global.scss'; // Add this import at the top

.container {
  position: relative;
  top: 60px;
  height: calc(100vh - 70px);
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem; // Added padding for consistent alignment
  scroll-behavior: smooth;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include mobile {
    top: 55px;
    height: calc(100vh - 55px);
    padding: 0 1rem; // Adjust padding for smaller screens
  }
}
