@import '../../global.scss';

.contact {
  padding: 60px 0; // Removed horizontal padding
  min-height: 500px;
  display: flex;
  justify-content: center;
  width: 100%; // Added
  box-sizing: border-box; // Added

  @include mobile {
    padding: 40px 0;
  }

  .wrapper {
    max-width: 800px;
    width: 90%; // Changed from 100%
    padding: 0; // Removed padding
    box-sizing: border-box; // Added

    @include mobile {
      width: 85%;
    }

    .title {
      margin-bottom: 40px;
      text-align: center;

      .dark-mode & {
        color: $secColor;
      }

      @include mobile {
        margin-bottom: 30px;
      }
    }

    form {
      background-color: transparent;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include mobile {
        gap: 16px;
        width: 90%; // Make form narrower
        margin: 0 auto; // Center the form
      }

      .input-container {
        position: relative;
        width: 100%;

        input,
        textarea {
          width: 100%;
          padding: 16px;
          border-radius: 8px;
          border: 2px solid rgba($mainColor, 0.2);
          background-color: transparent;
          transition: all 0.3s ease;
          font-size: 16px;

          @include mobile {
            padding: 12px;
            font-size: 14px;
          }

          &:focus {
            outline: none;
            border-color: $mainColor;
            box-shadow: 0 2px 8px rgba($mainColor, 0.1);
          }

          .dark-mode & {
            color: $secColor;
            border-color: rgba($secColor, 0.2);

            &::placeholder {
              color: rgba($secColor, 0.6);
            }

            &:focus {
              border-color: $secColor;
              box-shadow: none;
            }
          }
        }

        input {
          height: 54px;

          @include mobile {
            height: 45px;
          }
        }

        textarea {
          min-height: 200px;
          resize: vertical;

          @include mobile {
            min-height: 150px;
          }
        }
      }

      button {
        align-self: center;
        padding: 12px 32px;
        border-radius: 8px;
        border: 2px solid $mainColor;
        background-color: $mainColor;
        color: $secColor;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease;

        @include mobile {
          padding: 10px 24px;
          font-size: 14px;
        }

        &:hover {
          transform: translateY(-2px);
          background-color: transparent;
          color: $mainColor;
        }

        &:active {
          transform: translateY(0);
        }

        .dark-mode & {
          background-color: $secColor;
          border-color: $secColor;
          color: $mainColor;

          &:hover {
            background-color: transparent;
            color: $secColor;
          }
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
          transform: none;

          &:hover {
            transform: none;
          }
        }
      }

      .success-message {
        color: #66bb6a;
        font-size: 16px;
        text-align: center;
        margin-top: 16px;

        @include mobile {
          font-size: 14px;
          margin-top: 12px;
        }
      }
    }
  }
}
