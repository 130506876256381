@import '../../global.scss';
.works {
  padding: 50px 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 10px;

  .java {
    background-color: #fff9c4;
  }
  .swing {
    background-color: #ffcdd2;
  }
  .javaFx {
    background-color: #fff3e0;
  }
  .reactJs {
    background-color: #b3e5fc;
  }
  .cs,
  .remix {
    background-color: #e1bee7;
  }
  .python,
  .typescript {
    background-color: #bbdefb;
  }
  .firebase {
    background-color: #ffecb3;
  }
  .supabase {
    background-color: #c8e6c9;
  }
  .javascript {
    background-color: #fff59d;
  }
  .ruby {
    background-color: #ffcdd2;
  }
  .cardContainer {
    align-self: center;
    margin: 0px auto;
    height: 65%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    @include mobile {
      height: 90%;
      padding: 0 10px; // Add padding to prevent overshooting
    }

    .bottom-row,
    .top-row {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-bottom: 20px;
      @include mobile {
        flex-direction: column;
        align-items: center;
        gap: 15px; // Reduce gap on mobile
        margin-bottom: 15px;
      }
    }
    .card {
      // Add these new styles to override MUI's hover effect
      .MuiCardActionArea-root {
        background-color: inherit !important;

        &:hover {
          background-color: inherit !important;
        }

        .MuiCardActionArea-focusHighlight {
          background-color: transparent !important;
        }
      }

      .MuiCardContent-root {
        background-color: inherit !important;

        &:hover {
          background-color: inherit !important;
        }
      }
      color: inherit;
      // padding: 10px 0px;

      .tag {
        color: $background-color-dark;
        width: 100px;
        height: 20px;
        border-radius: 2rem;
        padding: 0px 10px;
        font-size: 12px;
        margin-right: 5px;
        box-shadow: 0px 0px 0px 1px #000;
        // outline: 1px solid;
        @include mobile {
          font-size: 10px;
        }
      }
      .header {
        font-size: 20px;
        font-weight: 500;
        @include mobile {
          font-size: 15px;
        }
      }
      .description {
        color: inherit;
        font-size: 12px;
        margin-bottom: 20px; // Added bottom margin to description

        @include mobile {
          font-size: 11px;
        }
      }
      .divider {
        width: 100%;
        border-color: #000;
        border-width: 1px;
        height: 1px;
        background-color: #a1a1aa;
        opacity: 0.3;
        margin: 10px 00px;
        // @include mobile {
        //   display: none;
        // }
      }
      @include mobile {
        width: 85%; // Reduce width on mobile
        margin: 0; // Remove margin on mobile
        padding: 15px; // Reduce padding on mobile

        // Reduce hover scale on mobile
        &:hover {
          transform: scale3d(1.02, 1.02, 1);
        }
      }
      flex: 1 1 1;
      margin: 25px 30px;
      color: inherit;
      background-color: inherit;
      border-radius: 0.5rem;
      padding: 20px 20px 30px 20px; // Added more padding at the bottom

      transition: transform 0.25s ease-in-out;
      &:hover {
        transform: scale3d(1.05, 1.05, 1);
      }
    }
  }
}
